import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./App.scss";
import axios from "axios";
import Home from "../views/HomeNew/component";
import crossIcon from "../images/cross-icon.svg";
import Sidebar from "../views/SideBar/SideBar";
import OtpVerify from "../views/OtpVerify/component";
import SellerRegistration from "../views/SellerRegistration/component";
import ShopDetails from "../views/ShopDetails/component";
import { Modal } from "reactstrap";
import KikoDashboard from "../views/KikoDashboard/component";
import ItcModal from "../views/Common/Modal/ItcModal";
import AddSingleCatalog from "../views/AddSingleCatalog/component";
import Categories from "../views/Categories/component";
import Inventry from "../views/Inventry/component";
import SsoLoginError from "../views/SsoLoginError/SsoLoginError";
import Wallet from "../views/Wallet/component";
import NewOndcOrder from "../views/NewOrders/component";
import KikoOrder from "../views/KikoOrders/component";
import { useEffect, useState } from "react";
import { GET_USER } from "../api/apiList";
import { handleError, notify } from "../utils";
import API from "../api";
import Support from "../views/Support/support";
import WebsiteSettlement from "../views/Settlement/WebsiteSettlement";
import OndcSettlement from "../views/Settlement/OndcSettlement";
import PreRazorpay from "../views/Razorpay/PreRazorpay";
import AdminAccess from "../views/Admin-Access/component";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import DeliveryPolicy from "../views/Common/Delivery-policy/delivery-policy";
import Disclaimer from "../views/Common/Disclaimer/disclaimer";
import PrivacyPolicy from "../views/Common/Privacy-policy/privacy-policy";
import ReturnPolicy from "../views/Common/Return-Refund-Policy/return-refund-policy";
import TermsCondition from "../views/Common/Terms-condition/terms-condition";
import NewReturn from "../views/NewReturn/component";
import { app, analytics } from "../../src/firebase/FirebaseConfig"
import { getAnalytics, logEvent } from 'firebase/analytics';
import SsoOtp from "../views/SsoOtp/component";
import PermissionAlertP from "../components/Modal/PermissionAlertPopup";
import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

// import gifImage from '../images/loader.gif';
const fireBaseTest = async () => {
  const isMessagingSupported = await isSupported();
  return isMessagingSupported;
}

const getSellerDetails = () => {
  try {
    return JSON.parse(localStorage.getItem("user") || "");
  } catch (error) {
    return null;
  }
};

const getSellerToken = () => {
  try {
    return JSON.parse(localStorage.getItem("token") || "");
  } catch (error) {
    return null;
  }
};

function App() {
  const [orderNotification, setOrderNotification] = useState({})
  const [isFromItc, setIsFromItc] = useState(false)
  const [isItcLoginSuccess, setIsItcLoginSuccess] = useState(false)
  const [isRegularFlow, setIsRegularFlow] = useState(false)
 let otpSend =false
  const antIcon = <LoadingOutlined style={{ fontSize: 54 }} spin />;

  logEvent(analytics, 'SellerAppLogin', { mobile_number: 'WebsiteMobileLogin' });

  function requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      } else {
        console.log('Unable to get permission to notify.');
      }
    });
  }

  const firebaseMessage = async () => {
    const isSupported = await fireBaseTest()
    if (isSupported) {
      const messaging = getMessaging(app)
      getToken(messaging, { vapidKey:'BH8BmX4r4eTnzrdUA2g_nVqsv7aPsNrO-6E7ibIiyMnCChUE8D82TwEgwXGG3bhZqFNBUB4zXJAodZsMOe1FYo4' }).then((currentToken) => {
        if (currentToken) {
          localStorage.setItem("webToken", JSON.stringify(currentToken));
        } else {
          requestPermission()
        }
      }).catch((err) => {
        // handleError(err)
        console.log('An error occurred while retrieving token. ', err);
      });

      onMessage(messaging, (payload) => {
        const data = payload;
        console.log("dataonMessage",data);
        setOrderNotification({
          notificationTitle: data?.data?.title,
          notificationBody: data?.data?.body
        })
      });
    }
  }
  firebaseMessage()
  useEffect(() => {
    let details = navigator.userAgent;
    const user = getSellerDetails()
    const localToken = getSellerToken()
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const userId = searchParams.get("userId");
    const uuId = searchParams.get("uuId");
    const uuid = searchParams.get("uuid");
    const mobile = searchParams.get("mobile");
    const token = searchParams.get("token");
    if ((uuId || uuid) && (uuId != "" || uuid != "") && mobile && mobile != "") {
      setIsRegularFlow(false)
      setIsFromItc(true)
      let obj = {
        brandProviderId: uuId && uuId != "" ? uuId.toString() : uuid.toString(),
        phone: mobile,
        brandName: "ITC"
      }
      if (user?.mobile && mobile && user?.mobile != mobile) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
      }
      if (mobile.length == 10) {
        !otpSend && ssoLogin(obj)
      }
      else {
        notify("error", "Please Enter valid number!"); setIsFromItc(false)
      }
    }
    else {
      setIsRegularFlow(true)
    }
    const path = searchParams.get("path")
      ? searchParams.get("path")
      : "/shopdetails";
    if (user && user !== "" && user._id == userId && token !== "" && token == localToken) {
      localStorage.setItem("isAppView", true);
      sessionStorage.setItem("hasReloaded", "true");
      if (path && window.location.pathname !== path) {
        window.location.href = path;
        return;
      }
    }
    else if (userId && userId !== "" && token && token !== "") {
      localStorage.setItem("isAppView", true);
      localStorage.setItem("token", JSON.stringify(token));
       getUser(userId, path);
    }
  }, []);


  const ssoLogin = async (obj) => {
    const mobile = obj.phone;
     otpSend =true;
    obj.phone = `+91${obj.phone}`;
    const options = {
      method: "post",
      url: `${process.env.REACT_APP_KIKO_API_V1}/user/ssologin`,
      headers: {
        desktop: true,
      },
      data: obj,
    };
    try {
      const result = await axios(options);
      if (result) {
        let kikoUser = result?.data?.isUserExists
        setIsItcLoginSuccess(true)
        if (!kikoUser) {
          if (result?.data?.otp) {
            const brandProviderId = obj?.brandProviderId
            var url = `/sso-otp?mobile=${encodeURIComponent(mobile)}&brandProviderId=${brandProviderId}`;
            window.location.href = url;
          }
          else {
            window.location.href = "/orders";
            localStorage.setItem("user", JSON.stringify(result?.data?.user));
            localStorage.setItem("token", JSON.stringify(result?.data?.token));
          }
        }
        else {
          window.location.href = "/sso-login"
        }
      }
    } catch (error) {
      handleError(error);
    }
  };


  const getUser = async (userId, path) => {
    try {
      const response = await API.post(GET_USER, {
        _id: userId,
      });
      if (response?.data?.success) {
        sessionStorage.setItem("hasReloaded", "true");
        localStorage.setItem("user", JSON.stringify(response?.data?.result));
        if (path && window.location.pathname !== path) {
            window.location.href = path;
           return null;
        }
      }
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <>
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={3000}
        toastStyle={{ backgroundColor: "crimson" }}
      />
      {(isItcLoginSuccess || isRegularFlow) ?
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <PageWithSidebar>
                  <Home isFromItc={isFromItc} />
                </PageWithSidebar>
              }
            />
            <Route
              path="/delivery-policy"
              element={
                <DeliveryPolicy />
              }
            />
            <Route
              path="/disclaimer"
              element={
                <Disclaimer />
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <PrivacyPolicy />
              }
            />
            <Route
              path="/return-policy"
              element={
                <ReturnPolicy />
              }
            />

            <Route
              path="/terms-condition"
              element={
                <TermsCondition />
              }
            />
            <Route
              path="/verifyotp"
              element={
                <PageWithSidebar >
                  <OtpVerify />
                </PageWithSidebar>
              }
            />
            <Route
              path="/registration"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <SellerRegistration />
                </PageWithSidebar>
              }
            />

            <Route
              path="/shopdetails"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <ShopDetails />
                </PageWithSidebar>
              }
            />

            <Route
              path="/kikodashboard"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <KikoDashboard />
                </PageWithSidebar>
              }
            />

            <Route
              path="/addsinglecatalog"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <AddSingleCatalog />
                </PageWithSidebar>
              }
            />

            <Route
              path="/categories"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <Categories />
                </PageWithSidebar>
              }
            />

            <Route
              path="/inventory"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <Inventry />
                </PageWithSidebar>
              }
            />

            <Route
              path="/wallet"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <Wallet />
                </PageWithSidebar>
              }
            />

            {/* <Route
              path="/orders"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <Orders />
                </PageWithSidebar>
              }
            /> */}
            <Route
              path="/orders"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <NewOndcOrder />
                </PageWithSidebar>
              }
            />

            <Route
              path="/kiko-orders"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <KikoOrder />
                </PageWithSidebar>
              }
            />

            {/* <Route
          path="/razorpay"
          element={
            <PageWithSidebar>
              <RazorpayModule />
            </PageWithSidebar>
          }
        /> */}

            <Route
              path="/support"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <Support />
                </PageWithSidebar>
              }
            />
            <Route
              path="/website-settlement"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <WebsiteSettlement />
                </PageWithSidebar>
              }
            />
            <Route
              path="/ondc-settlement"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <OndcSettlement />
                </PageWithSidebar>
              }
            />
            {/* <Route
              path="/return"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <Return />
                </PageWithSidebar>
              }
            /> */}
            <Route
              path="/return"
              element={
                <PageWithSidebar notification={orderNotification} setNotification={setOrderNotification}>
                  <NewReturn />
                </PageWithSidebar>
              }
            />
            <Route
              path="/preRazorpay"
              element={
                <PageWithSidebar>
                  <PreRazorpay />
                </PageWithSidebar>
              }
            />
            <Route path="/admin-access" element={<AdminAccess />} />
            <Route path="/sso-login" element={<SsoLoginError />} />
            <Route path="/sso-otp" element={<SsoOtp />} />
          </Routes>
        </Router>:
           <Spin indicator={antIcon} className="loader" />
      }
    </>
  );
}

function PageWithSidebar({ children, notification, setNotification }) {
  const user_data = getSellerDetails();
  const isHome = window.location.pathname === "/";
  const isOtpVerify = window.location.pathname === "/verifyotp";
  const isSellerRegistration = window.location.pathname === "/registration";
  const navigate = useNavigate();
  const isAppView = localStorage.getItem("isAppView") ?? null;
  const [permissionAlertPopUp, setPermissionAlertPopUp] = useState({ permission: false, type: "" })

  useEffect(() => {
    if (window && window.flutter_inappwebview) {
      document.documentElement.style.setProperty('--mainColor', '#2874f0')
      document.documentElement.style.setProperty('--activeColor', '#5691f1')
    }
  }, []);
  return (
    <>
      <div className="KikoDashboardWrapper">
        <div className="dashboardWrapper">
          {isHome ||
            isOtpVerify ||
            (!user_data?.isProfileComplete &&
              !user_data?.isProfileSkip &&
              isSellerRegistration) ? null : (
            <Sidebar />
          )}
          {children}
          <>
            <Modal
              isOpen={notification?.notificationTitle && notification?.notificationTitle !== ""}
              onClose={() => {
                setNotification({});
              }}
              style={{ width: "350px" }}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <div className="container p-5">
              {/* <img
                  src={crossIcon}
                  onClick={() => {
                    setNotification({});
                  }}
                  alt=""
                /> */}
                <div className="Insufficient-modal">
                  <h4 className="text-center mb-0">{notification?.notificationTitle}</h4>
                  <p>{notification?.notificationBody}</p>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-3">
                  <button
                    onClick={() => {
                      navigate("/orders");
                      setNotification({});
                    }}
                    className="btn btn-success w-100"
                  >
                    ONDC Orders
                  </button>
                  <button
                    onClick={() => {
                      setNotification({});
                    }}
                    className="btn btn-danger w-100"
                  >
                    Close
                  </button>
                </div>
              </div>
            </Modal>
          </>
          <>
             <PermissionAlertP permissionAlertPopUp={permissionAlertPopUp} setPermissionAlertPopUp={setPermissionAlertPopUp} />
          </>
        </div>
      </div>
    </>
  );
}
export default App;
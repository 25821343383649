export const SELLER_LOGIN = "sellerOndc/seller-login";
export const CHECK_SLUG = "sellerOndc/check-slug";
export const REQUEST_OTP = "sellerOndc/request-otp-v2";
export const UPDATE_ORDER = "sellerOndc/update-order";
export const UPDATE_VENDOR_PROFILE = "sellerOndc/update-vendor-profile";
export const UPDATE_VENDOR_PROFILE_DETAIL ="sellerOndc/update-vendor-profile-detail";
export const GET_CATEGORY = "sellerOndc/get-catalogue-categories";
export const CREATE_CATELOGUE = "sellerOndc/create-catalogues";
export const UPDATE_CATELOGUE = "sellerOndc/update-catalogues";
export const CREATE_BULK_CATELOGUE = "sellerOndc/create-catalogues-bulk";
export const INVENTORY = "sellerOndc/get-catalogues";
export const DELETE_INVENTORY = "sellerOndc/delete-catalogue";
export const ORDER_LIST = "sellerOndc/get-ondc-orders-list";
export const ORDER_LIST_EXPORT = "sellerOndc/get-ondc-orders-list-export";
export const INVENTORY_LIST = "sellerOndc/get-ondc-orders-inventory-list";
export const ONDC_OVERDUE_ORDER_LIST = "sellerOndc/get-ondc-overdue-orders-list";
export const RETURN_LIST = "sellerOndc/get-return-orders";
export const RETURN_LIST_V2 = "sellerOndc/get-return-orders-v2";
export const RETURN_ORDER_DETAIL = "sellerOndc/get-return-order-detail";
export const SINGLE_ORDER_DETAILS = "sellerOndc/get-single-order-details";
export const PAYMENT_DATA = "sellerOndc/fetch-website-settlements";
export const ONDC_PAYMENT_DATA = "sellerOndc/fetch-ondc-settlements";
export const INVOICE_MONTHLY_DATA = "sellerOndc/seller-monthly-invoice";
export const GET_USER = "sellerOndc/fetch-customer-by-id";
export const GET_CATELOGUE = "sellerOndc/get-catalogues-by-barcode";
export const GET_CATELOGUE_VARIENT = "sellerOndc/get-catalogue-varient";
export const DELETE_VARIENT = "sellerOndc/delete-varient";
export const GET_SELLER_LIST = "sellerOndc/fetch-Seller-list";
export const KIKO_ORDERS = "kikoorders/microWebsite-seller-orders";
export const AUTOCOMPLETE = "/maps/autocomplete-v2";
export const MAPS_TOKEN = "/maps/gettoken";
export const GET_SELLER = "/user/fetch-seller";
export const SSO_LOGIN = "/user/ssologin";
export const WALLET_DATA = "/wallet/get-wallet-transaction";
export const ADD_WALLET_MONEY = "/wallet/add-wallet";
export const SETTING = "/settings";
export const ADD_ENQUIRY = 'sellerOndc/add-enquiry'
export const ODNC_COUPON = 'sellerOndc/get-ondc-offer'
export const CREATE_ODNC_COUPON = 'sellerOndc/create-ondc-offers'
export const DELETE_ODNC_COUPON = 'sellerOndc/delete-ondc-offer'
export const SEARCH_AUTOCOMPLETE_CATELOGUE = 'sellerOndc/autoComplete-catelogue-search'
export const FETCH_COUPON_CATELOGUE = 'sellerOndc/fetch-offer-catalogue'
export const CREATE_BULK_MASTER_CATELOGUE = "sellerOndc/create-master-catalogues-bulk";
export const CATELOGUE_BRAND = "sellerOndc/get-catalogues-brand";
export const CREATE_UPDATE_CUSTOM_MENU = "sellerOndc/custom-menu";
export const FETCH_MENU_LIST = "sellerOndc/fetch-menu-by-id";
export const CATELOGUE_LIST = "sellerOndc/get-catalogues-for-User";
export const UPDATE_CATELOGUE_EXCEL = "sellerOndc/update-catelogue-status-excel";
